<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div class="uk-form-controls uk-width-small">
                    <select class="uk-select" id="form-horizontal-select" v-model="filterType">
                        <option :value=null>Select Filter</option>
                        <option :value='"fullname"'>Fullname</option>
                        <option :value='"email"'>Email</option>
                        <option :value='"phone_number"'>Phone Number</option>
                    </select>
                </div>
                <div class="uk-width-medium">
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input :disabled="filterType === null" v-model=query class="uk-input" @keyup.enter="onQueryEnter" type="text" placeholder="Search">
                    </div>
                </div>
                <div class="uk-inline">
                    <button
                        v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])"
                        class="uk-button-primary"
                        style="width:100px; cursor:pointer; height:40px"
                        @click="createUser"
                    >Create</button>
                </div>
            </div>
        </div>

        <span v-if="isLoading" uk-spinner="ratio: 2" class="uk-position-center uk-margin-auto"></span>
        <div v-else class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-center">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-width-medium">Fullname</th>
                            <th class="uk-text-center uk-width-small">Email</th>
                            <th class="uk-text-center uk-width-1-6">Phone</th>
                            <th class="uk-text-center uk-width-small">Verif Code</th>
                            <th class="uk-text-center">Level</th>
                            <th class="uk-text-center">Role</th>
                            <th class="uk-text-center uk-width-1-5">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="10"/>
                    <tbody v-if="users.docs.length>0">
                        <tr v-for="(user, i) in users.docs" :key="i">
                            <td>{{user.fullname || '-'}}</td>
                            <td>{{user.email || '-'}}</td>
                            <td>{{user.phone_number || '-'}}</td>
                            <td>{{user.verification_code || '-'}}</td>
                            <td>{{user.level}}</td>
                            <td>{{rolesName(user.roles)}}</td>
                            <td>
                                <button
                                    class="uk-button-primary"
                                    style="width:100px; cursor:pointer; height:30px; background:#333747"
                                    v-clipboard:copy="user._id"
                                    v-clipboard:success="onCopy"
                                >Copy Id</button>
                                <br>
                                <button
                                    v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])"
                                    class="uk-button-default uk-margin-small-top"
                                    style="width:100px; cursor:pointer; height:30px"
                                    @click="editUser(user)"
                                >Edit</button>
                                <br>
                                <button
                                    v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])"
                                    class="uk-button-danger uk-margin-small-top"
                                    style="width:100px; cursor:pointer; height:30px"
                                    @click="delUser(user)"
                                >Delete</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="7"/>
                </table>
            </div>
            <pagination
                :total-data="users.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <formUserModal
            v-if="isShowFormUserModal"
            source="employer"
            :user-form="userForm"
            :form-is-edit="formIsEdit"
            :is-fetching="isFetching"
            :role-options="role_options"
            :partner-options="partner_options"
            :company-options="company_options"
            :internal-role-options="internal_role_options"
            :office-options="office_options"
            :is-loading="isLoading"
            @setPartnerValues="setPartnerValues"
            @setCompanyValues="setCompanyValues"
            @setRoleValues="setRoleValues"
            @setPropertyValues="setPropertyValues"
            @hideModal="hideModal"
            @successAction="successAction"
        />

        <div id="formDetailModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Delete User</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Are you sure to delete this user?</p>
                    <p>Name: <b>{{userForm.fullname}}</b></p>
                    <p>Email: <b>{{userForm.email}}</b></p>
                    <p>Phone Number: <b>{{userForm.phone_number}}</b></p>
                    <p>Roles: <b>{{userForm.roles.name}}</b></p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger" type="button" @click="markDelete">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import Pagination from '@/components/globals/Pagination';
import LoadingTable from '@/components/globals/tables/Loading';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import formUserModal from "./formUserModal";
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    data() {
        return {
            user_cred: getUserLogin(),
            meta: {
                roles: 'employer',
                limit: 100,
                page: 1,
            },
            userForm: {
                fullname: '',
                email: '',
                password: '',
                phone_number: '',
                roles: '',
                partner_ids: [],
                notes_superadmin: '',
                geoloc: null,
                company_id: null,
                client_role_id: null,
                client_office_id: null,
                client_office_options: [],
                user_verified: false,
                verification_code: null,
                login_via_email: true
            },
            query: '',
            filterType: null,
            role_options: [
                {name: 'Employer', value: ['asd123']},
                {name: 'Admin Client', value: ['admin-client']},
            ],
            company_options: [],
            office_options: [],
            partner_options: [],
            internal_role_options: [],
            isLoading: false,
            isFetching: false,
            formIsEdit: false,
            reportUser: null,
            json: {
                'Full Name': 'fullname',
                'Phone': 'phone_number',
                'Email': 'email',
                'Register At': 'created_at',
                'Address': 'address'
            },
            images: PREFIX_IMAGE,
            isShowFormUserModal: false
        };
    },
    components: {
        Pagination,
        LoadingTable,
        EmptyTable,
        formUserModal
    },
    computed : {
        ...mapGetters({
            users: 'user/users',
            companies: 'company/companies',
            company: 'company/company',
            company_roles: 'company_role/company_roles',
            properties: 'property/properties',
        })
    },
    watch: {
        meta() {
            this.setUserMeta();
        }
    },
    mounted() {
        this.user_cred = getUserLogin();
        this.setUserMeta();
        this.resetForm();
    },
    methods: {
        ...mapActions({
            getUser: 'user/getUser',
            deleteUser: 'user/deleteUser',
            getCompanies: 'company/getCompanies',
            getCompanyDetail: 'company/getCompanyDetail',
            getCompanyRoles: 'company_role/getCompanyRoles',
            getProperties: 'property/getProperties',
            getReportUser: 'user/getReportUser',
        }),
        fullName(data) {
            return formatter.getFullname(data);
        },
        async setUserMeta() {
            await this.getUser(this.meta);
            this.isLoading = false;
        },
        async setPartnerValues(query) {
            try {
                this.isFetching = true;

                await this.getCompanies({name: query});
                this.partner_options = this.companies.docs.map(
                    obj => ({name: obj.name, value: obj._id})
                );
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetching = false;
            }
        },
        async setCompanyValues(query) {
            try {
                this.isFetching = true;

                await this.getCompanies({name: query});
                this.company_options = this.companies.docs.map(
                    obj => ({name: obj.name, value: obj._id})
                );
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetching = false;
            }
        },
        async setRoleValues(query) {
            try {
                this.isFetching = true;

                await this.getCompanyRoles({name: query, is_internal: true, company_id: this.userForm.company_id.value});
                this.internal_role_options = this.company_roles.docs.map(
                    obj => ({name: obj.name, value: obj._id})
                );
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetching = false;
            }
        },
        async setPropertyValues(query) {
            try {
                this.isFetching = true;

                await this.getProperties({name: query, company_id: this.userForm.company_id.value});
                this.office_options = this.properties.docs.map(
                    obj => ({name: obj.name, value: obj._id})
                );
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetching = false;
            }
        },
        resetMeta() {
            this.meta = {
                roles: 'employer',
                limit: 100,
                page: 1,
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        onQueryEnter() {
            this.resetMeta();
            this.meta[this.filterType] = this.query;
            this.setUserMeta();
        },
        createUser(){
            this.formIsEdit = false;
            this.resetForm();
            this.showModal();
        },
        editUser(user) {
            this.resetForm();
            this.formIsEdit = true;
            this.fillForm(user);
            this.showModal();
        },
        delUser(user) {
            this.resetForm();
            this.formIsEdit = true;
            this.fillForm(user);
            this.showDeleteModal();
        },
        async markDelete(){
            try {
                this.isLoading = true;
                const response = await this.deleteUser(this.userForm.id);
                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('User Deleted!');
                    this.hideDeleteModal();
                    this.setUserMeta();
                } else {
                    notificationDanger('Failed to delete user!');
                }
            } catch (err) {
                notificationDanger(err);
            }

        },
        successAction(){
            this.resetForm();
            this.setUserMeta();
        },
        showModal() {
            this.isShowFormUserModal = true;
        },
        hideModal() {
            this.isShowFormUserModal = false;
        },
        async showDeleteModal() {
            await window.UIkit.modal('#formDetailModal').show();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#formDetailModal').hide();
            this.resetForm();
        },
        fillForm(user) {
            try {
                this.userForm.id = user._id;
                this.userForm.fullname = user.fullname;
                this.userForm.email = user.email;
                this.userForm.phone_number = user.phone_number;
                this.userForm.password = '';
                this.userForm.notes_superadmin = user.notes_superadmin;
                this.userForm.geoloc = user.geoloc;
                this.userForm.user_verified = user.user_verified;
                this.userForm.verification_code = user.verification_code;
                this.userForm.login_via_email = user.verification_code.toString().length === 6;
                this.userForm.roles = this.role_options.find(
                    e => e.value[0] === user.roles
                );
                this.userForm.client_role_id = user.client_role ? {
                    name: user.client_role.name,
                    value: user.client_role._id
                } : null;
                this.userForm.client_office_id = user.client_office ? {
                    name: user.client_office.name,
                    value: user.client_office._id
                } : null;
                this.userForm.company_id = user.company ? {
                    name: user.company.name,
                    value: user.company._id
                } : null;
                user.partner_ids.forEach(async partner_id => {
                    await this.getCompanyDetail(partner_id);
                    this.userForm.partner_ids.push({
                        name: this.company.name, value: this.company._id
                    });
                });
                this.userForm.client_office_options = user.client_office_options && user.client_office_options.length > 0 ?
                    user.client_office_options.map(opt => ({name: opt.name, value: opt._id})) :
                    [];

            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.userForm = {
                fullname: '',
                email: '',
                password: '',
                phone_number: '',
                roles: '',
                partner_ids: [],
                notes_superadmin: '',
                geoloc: null,
                company_id: null,
                client_role_id: null,
                client_office_id: null,
                client_office_options: [],
                user_verified: false,
                verification_code: null,
                login_via_email: true
            };
            this.$validator.reset();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        async fetchReportUser() {
            await this.getReportUser().then((res) => {
                this.reportUser = res;
            }).catch((err) => {
                console.log(err);
                this.reportUser = [];
            });
            return this.reportUser;
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        rolesName(role){
            for (const opt of this.role_options) {
                if (role == opt.value[0]) {
                    return opt.name;
                }
            }
        },
    },
};
</script>
